import React, { useState, useEffect, useRef } from "react";
import i18n from "i18next";
// Layout
import Layout from "../components/layout";
import SEO from "../components/seo";
import { withNamespaces } from "react-i18next";

import b2English from '../images/career/B2English.png';
import bogotaCO from '../images/career/bogotaDC.png';
import years from '../images/career/years.png';
import hybrid from '../images/career/hybrid.png';
import indifinite from '../images/career/indefinite.png';
import tobeAgreed from '../images/career/toBeAgreed.png';

import teamEs from './../components/ourDNA/serviceOurTeam/teamEs';
import teamEn from './../components/ourDNA/serviceOurTeam/teamEn';

import BannerOpenPosition from "../components/exceptionalCareers/BannerOpenPosition";
import DescriptionVacant from "../components/exceptionalCareers/DescriptionVacant";
import SeccionOtherVacancies from "../components/exceptionalCareers/SeccionOtherVacancies";
import { useParams } from "@reach/router";
import { getOffersById } from './../utils/api';
import { useStaticQuery, graphql } from "gatsby";

const OpenPosition = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([]);
  const [youtubeVideoId, setYoutubeVideoId] = useState('');
  const [team, setTeam] = useState(teamEs);
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView();
  const [jobDetails, setJobDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Use useStaticQuery to get the data
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  // Get the current path and extract the jobId
  const currentPath = typeof window !== "undefined" ? window.location.pathname : "";
  const jobId = currentPath.replace("/open-position/", "");

  useEffect(() => {
    let isMounted = true;
  
    async function fetchJobDetails() {
      try {
        if (jobId && isMounted) {
          const details = await getOffersById(jobId);
          setJobDetails(details);
          setIsLoading(false);

        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    
    fetchJobDetails();
  }, [jobId]);
  

  const attributesMainTitle = { 
      id: "OpenPosition", 
      title: i18n.t('pageourdna.ourteam'), 
      styles: { bulletColor: "dark-blue", 
      textColor: "light-blue", fontSize: "44", 
      fontSizeMobile: "36", alignMobile: "left", 
      alignDesktop: "left", marginL: "20" 
    } 
  }

  const sSkillsArray = jobDetails?.data?.obj?.job_JobSoftSkill.map((skill) => skill.jobSoftSkill.softSkillName) || [];

  const tSkillsArray = jobDetails?.data?.obj?.job_JobTechnicalAbility.map((skill) => skill.jobTechnicalAbility.discipline) || [];

  const professionsArray = jobDetails?.data?.obj?.job_JobProfession.map((profession) => profession.jobProfession.profession) || [];

  const otSkillsArray = jobDetails?.data?.obj?.job_OtherTechnicalAbility.map((skill) => skill.technicalAbility+': '+skill.jobTechnicalAbilityLevel.level) || [];
  
  const languageCards = jobDetails?.data?.obj?.job_JobLanguage?.map((language) => ({
    image: b2English, // Cambia esto para que refleje la imagen correcta para cada idioma
    title: `${language.jobLanguageLevel.languageLevel} ${language.jobLanguage.language}`,
    text: i18n.t("pagejoinourteam.jobDetails.language"),
  })) || [];

  const specsArray = [
    ...languageCards,
    {
        image: bogotaCO,
        title: jobDetails && jobDetails.data.obj.jobLocation.cityName+" " + jobDetails.data.obj.jobLocation.countryName || "Sin datos",
        text: i18n.t("pagejoinourteam.jobDetails.location"),
    },
    {
        image: years,
        title: jobDetails && jobDetails.data.obj.jobExperience.experience || "Sin datos",
        text: i18n.t("pagejoinourteam.jobDetails.experience"),
    },
    {
        image: hybrid,
        title: jobDetails && jobDetails.data.obj.jobLocationModality.name || "Sin datos",
        text: i18n.t("pagejoinourteam.jobDetails.mode"),
    },
    {
        image: indifinite,
        title: jobDetails && jobDetails.data.obj.jobContract.contract || "Sin datos",
        text: i18n.t("pagejoinourteam.jobDetails.contract"),
    },
    {
        image: tobeAgreed,
        title: jobDetails && jobDetails.data.obj.showSalary
        ? "A convenir"
        : (jobDetails && jobDetails.data.obj.jobSalary
          ? jobDetails.data.obj.jobSalary + " " + jobDetails.data.obj.jobCurrency.shortName
          : "Por acordar"),
        text: i18n.t("pagejoinourteam.jobDetails.salary"),
    },
   
    ];

    const arrayTitle = {
      title: (jobDetails && jobDetails.data && jobDetails.data.obj && jobDetails.data.obj.name) || "",
      detail: ((jobDetails && jobDetails.data && jobDetails.data.obj && jobDetails.data.obj.jobSector && jobDetails.data.obj.jobSector.sector) || "") + ". " + ((jobDetails && jobDetails.data && jobDetails.data.obj && jobDetails.data.obj.jobSubLevel && jobDetails.data.obj.jobSubLevel.name) || ""),
    };
    
    const descriptionJob = (jobDetails && jobDetails.data && jobDetails.data.obj && jobDetails.data.obj.description) || "";
    

  return (
    <Layout>
        <SEO title={"Open position | Exsis Digital Angels"} description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
      
       <BannerOpenPosition
          bannerImageContent={descriptionTitleBanner} 
          arrayTitle={arrayTitle}>
        </BannerOpenPosition>

        <DescriptionVacant descriptionJob={descriptionJob} sSkillsArray={sSkillsArray} 
        tSkillsArray={tSkillsArray} specsArray={specsArray} otSkillsArray={otSkillsArray}
        professionsArray={professionsArray}/>

        <SeccionOtherVacancies jobId={jobId}/>
           
    </Layout>
  );
};

export default  withNamespaces()(OpenPosition);
