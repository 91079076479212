import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import arrowLeft from "./../../images/home/arrowLeft.jpg"
import arrowRigth from "./../../images/home/arrowRigth.jpg"
import TargetWork from './../home/TargetWork';
import { LoadingContext } from '../../components/context/LoadingContext';
import i18n from "i18next";
import { Link } from '@reach/router';
import { getOffers } from './../../utils/api';
import { getOffersById } from './../../utils/api.js';
import Pagination from '@material-ui/lab/Pagination';
import TargetVacancies from './TargetVacancies';
import { makeStyles } from "@material-ui/core";
import imgV1 from "../../images/targetVacancies/imgVacancie1.png"; //customer
import imgV2 from "../../images/targetVacancies/imgVacancie2.png"; //analyst - qa
import imgV3 from "../../images/targetVacancies/imgVacancie3.png"; //developer
import imgV4 from "../../images/targetVacancies/imgVacancie4.png"; //project manager - n.a
import imgV5 from "../../images/targetVacancies/imgVacancie5.png"; //architect - engineer

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  item: {
    padding: theme.spacing(1.2),
  },
  avatar: { marginRight: theme.spacing(5) },
  paginator: {
    justifyContent: "flex-end",
    padding: "10px",
    marginBottom: "0px",
    fontFamily: "AvenirMedium"
  }
}));

const SeccionOtherVacancies = ({jobId}) => {

  const { windowWidth } = useContext(LoadingContext);

  const [initialValue, setInitialValue] = useState(0);
  const [finalValue, setFinalValue] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const targetVacanciesRef = useRef(null);
  const jobsPerPage = 3;
  const classes = useStyles();
  const currentVacancyId = jobId;

  const formatDate = (dateString, language) => {
    const months = {
      'es': ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    };
  
    const parts = dateString.split(' ');
    const day = parts[0];
    const monthText = parts[1].toLowerCase();
    const year = parts[2];
    const monthIndex = months['es'].indexOf(monthText);
  
    if (monthIndex !== -1) {
      const formattedMonth = months[language][monthIndex].substring(0, 3).charAt(0).toUpperCase() + months[language][monthIndex].substring(0, 3).slice(1);
      return `${day} ${formattedMonth} ${year}`;
    } else {
      // Si no se encuentra el mes, devolvemos la fecha original
      return dateString;
    }
  };

  const getVacancyImage = (jobName) => {
    const lowercaseJobName = jobName.toLowerCase(); // Convertir el nombre de la vacante a minúsculas para hacer coincidencias sin importar mayúsculas o minúsculas
  
    if (lowercaseJobName.includes("customer")) {
      return imgV1;
    } else if (lowercaseJobName.includes("analyst") || lowercaseJobName.includes("analista") || lowercaseJobName.includes("qa")) {
      return imgV2;
    } else if (lowercaseJobName.includes("developer") || lowercaseJobName.includes("dev") || lowercaseJobName.includes("desarrollador")) {
      return imgV3;
    } else if (lowercaseJobName.includes("project") || lowercaseJobName.includes("manager")) {
      return imgV4;
    } else if (
      lowercaseJobName.includes("architect") ||
      lowercaseJobName.includes("engineer") ||
      lowercaseJobName.includes("arquitecto") ||
      lowercaseJobName.includes("ingeniero")
    ) {
      return imgV5;
    } else {
      return imgV4;
    }
  };

  useEffect(() => {
    async function getJobs() {
      try {
        const response = await getOffers(); // Obtiene los datos de trabajo
        const jobsWithInitialTime = response.data.obj.map((job) => ({
          ...job,
          time: i18n.t("pagejoinourteam.vacancies.loadingData"), // Inicializa con el mensaje de carga
          ubication: `${job.jobLocation?.cityName || ""}, ${job.jobLocation?.countryName || ""}`,
          description: (job?.description.replace(/<[^>]+>/g, ' ').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '')).split(/\s+/).slice(0, 45).join(' ') + ' \u2026',
          timeCreated: formatDate(job.creationDate, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          timeLast: formatDate(job.editedDate, i18n.language) || i18n.t("pagejoinourteam.targetVacancies.timeCreatedEmpty"),
          publication: job.publicationDate,
          vacancyImage: getVacancyImage(job.name),
        }));
  
        setJobs(jobsWithInitialTime);
        setIsLoading(false);
  
        // Lanzar la segunda petición después de haber renderizado las tarjetas
        await Promise.all(jobsWithInitialTime.map(async (job) => {
          const jobInfo = await getJobInfoById(job.jobId);
          const correctedTime = jobInfo?.jobLocationModality?.name ?? i18n.t("pagejoinourteam.vacancies.nodata");
          setJobs(prevJobs => prevJobs.map(prevJob => prevJob.jobId === job.jobId ? { ...prevJob, time: correctedTime } : prevJob));
        }));
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    getJobs();
    if (targetVacanciesRef.current) {
      targetVacanciesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [i18n.language]);

  async function getJobInfoById(jobId) {
    try {
      const response = await getOffersById(jobId);
      const jobInfo = response.data.obj;
      const time = i18n.language === "en" ? jobInfo?.jobLocationModality?.nameEnglish : jobInfo?.jobLocationModality?.name;
      return {
        ...jobInfo,
        time: time || i18n.t("pagejoinourteam.vacancies.nodata"),
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  
  const handlePageChange = useCallback((event, newPage) => {
    setPage(newPage);
    if (targetVacanciesRef.current) {
      targetVacanciesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [setPage]);

  const paginatedJobs = jobs.slice((page - 1) * jobsPerPage, page * jobsPerPage);


  return (

    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card pyWork">
              <div className="flip-card-inner2 desktop:ml-16 mt-24">
              <div className='mobile:ml-4'>
                    <p className='subTitleOurServices'>{i18n.t("pagejoinourteam.jobDetails.join")}</p>
                    <p className='titleOurServices'>{i18n.t("pagejoinourteam.jobDetails.other")}</p> 
              </div>
                  

              { windowWidth >= 1025 ?
                          <>
                              {isLoading ? (
                                <div className=''>
                                  <p  className='resultsSearch mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 '>
                                    {i18n.t("pagejoinourteam.vacancies.loading")}</p>
                                </div>    
                              ) : (
                                <>
                                <div style={{ display: 'flex', justifyContent: "flex-end", width: '90%' }}>
                                  <Pagination
                                    count={Math.ceil(jobs.length / jobsPerPage)}
                                    page={page}
                                    onChange={handlePageChange}
                                    defaultPage={1}
                                    size="medium"
                                    classes={{ ul: classes.paginator }}
                                    />
                                    </div>

                                    <TargetVacancies workArray={paginatedJobs} />
                                      </>
                                )}
                          </>

                        :
                          <>
                              {isLoading ? (
                                <div className=''>
                                  <p  className='resultsSearchMob mt-10 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 '>
                                    {i18n.t("pagejoinourteam.vacancies.loading")}</p>
                                </div>    
                              ) : (
                                  <>
                                  <div style={{ display: 'flex', justifyContent: "flex-end", width: '90%' }}>
                                      <Pagination
                                        count={Math.ceil(jobs.length / jobsPerPage)}
                                        page={page}
                                        onChange={handlePageChange}
                                        defaultPage={1}
                                        size="medium"
                                        classes={{ ul: classes.paginator }}
                                        />
                                        </div>
                                        
                                    <TargetVacancies workArray={paginatedJobs} />
                                      </>
                                )}
                                </>
                              }

                        <Link to={"/exceptional-careers"}>
                        <button className='buttonViewMoreWork ml-auto mr-auto mt-12' style={{alignSelf:'center'}}>
                        <p className='textButtomViewMoreWork'>{i18n.t("pagejoinourteam.jobDetails.viewAll")}</p>
                        </button>
                        </Link>
              </div>
              
            </div>
  );
};

export default SeccionOtherVacancies;