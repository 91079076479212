import React, { useContext } from 'react';
import i18n from "i18next";
import Slider from "react-slick";
import { LoadingContext } from '../../components/context/LoadingContext';
import image1 from '../../images/exsisAcademy/imageBootcamp.png'
import image2 from '../../images/exsisAcademy/imageBootcamp2.png'

const DescriptionVacant = ({descriptionJob, sSkillsArray, tSkillsArray, specsArray, otSkillsArray, professionsArray}) => {

    const { windowWidth } = useContext(LoadingContext);

    const cleanedDescriptionJob = descriptionJob ? descriptionJob.replace(/&nbsp;/g, ' ') : '';
    

    return (

        <div className={windowWidth >= 1025 ? `pt-24 px-20 pb-8 bigdesktop:pb-24 bigdesktop:pt-72 backgroundDescriptionVacant`: ` p-6 backgroundDescriptionVacant`}>
          
             {windowWidth >= 1025 ? 
             <div className="flex w-full">
                  
                    <div className="contentWhyUs divInfoExsisAcademy">
                    <div className="eventRightTimeLineMobile">
                    <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.jobDetails")}</p> 
                    </div>
                       <div>
                        <p className="textInfoExsisAcademy2 my-8" dangerouslySetInnerHTML={{ __html: cleanedDescriptionJob }}>
                          </p>    

                        </div>   

                        <div className="eventRightTimeLineMobile mt-8 mb-8">
                            <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.profession")}</p> 
                          </div>

                        { (
                          professionsArray.map((item, i) => {
                              return (
                                  <div key={i.toString()} className="mb-6">
                                    <div className=''>
                                      <div className="contentSkillsVacant">
                                        <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                      </div>
                                    </div>
                                  </div>
                              );
                          }) 
                             )}

                        {tSkillsArray.length > 0 || otSkillsArray.length > 0 ? (
                          <div className="eventRightTimeLineMobile mt-8 mb-8">
                            <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.tSkills")}</p> 
                          </div>
                          ) : null}
                          
                            <div className='desktop:flex desktop:flex-wrap'>
                              {tSkillsArray.map((item, i) => {
                                return (
                                  <div key={i.toString()} className="mb-6">
                                    <div className=''>
                                      <div className="contentSkillsVacant">
                                        <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div className='desktop:flex desktop:flex-wrap'>
                              {otSkillsArray.map((item, i) => {
                                return (
                                  <div key={i.toString()} className="mb-6">
                                    <div className=''>
                                      <div className="contentSkillsVacant">
                                        <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            

                          {sSkillsArray.length > 0 ? (
                          <div className="eventRightTimeLineMobile mt-8 mb-8">
                            <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.sSkills")}</p> 
                          </div>
                          ) : null}
                          
                            <div className='desktop:flex desktop:flex-wrap'>
                            {sSkillsArray.map((item, i) => {
                              return (
                                <div className="mb-6" key={i.toString()}>
                                  <div className=''>
                                    <div className="contentSkillsVacant">
                                      <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            </div>
                            

                    </div> 
                    <div className="mt-6 divInfoExsisAcademyBootcamps ">
                         <div className="eventRightTimeLineMobile mb-8">
                            <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.specs")}</p> 
                            </div>

                            <div className='desktop:flex desktop:flex-wrap'>
                        { (
                          specsArray.map((item, i) => {
                              return (
                              <div key={i.toString()} className=" mb-6">
                                 <div className={windowWidth >= 1700 ?'contentSpecsVacantResponsive':'contentSpecsVacant'}>
                                 <div className="flex justify-center mb-2 mt-4 ">
                                        <img src={item.image} className="mr-1 mt-1"/>
                                    </div>
                                  <div className="flex flew-row text-left w-auto mt-2 "> 

                                    <div className="w-full text-left">
                                        <p className="pr-2 pl-2 titleSpec">{item.title}</p>
                                        <p className="pr-2 pl-2 textSpec">{item.text}</p>
                                    </div>
                                     
                                  </div>
                                </div>
                              </div>
                              );
                          }) 
                             )}
        

                            </div>
                            
                      
                   </div>
                    
                    </div>  :

                        <div className=" w-full">
                                        
                        <div className="contentWhyUs divInfoExsisAcademyMobile">
                        <div className="eventRightTimeLineMobile">
                        <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.jobDetails")}</p> 
                        </div>
                            <div>
                            <p className="textInfoExsisAcademy2Mobile mt-8" dangerouslySetInnerHTML={{ __html: descriptionJob }}>
                            </p>  
                           </div>

                           <div className="eventRightTimeLineMobile mt-8 mb-8">
                            <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.profession")}</p> 
                          </div>

                        { (
                          professionsArray.map((item, i) => {
                              return (
                                  <div key={i.toString()} className="mb-6">
                                    <div className=''>
                                      <div className="contentSkillsVacant">
                                        <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                      </div>
                                    </div>
                                  </div>
                              );
                          }) 
                             )}
                           
                        {tSkillsArray.length > 0 || otSkillsArray.length > 0 ? (
                        <div className="eventRightTimeLineMobile mt-8">
                          <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.tSkills")}</p> 
                        </div>
                        ) : null}

                        
                        <div className='mt-8'>
                        {tSkillsArray.map((item, i) => {
                          return (
                            <div key={i.toString()} className="mb-6">
                              <div className=''>
                                <div className="contentSkillsVacant">
                                  <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                         </div>
                        
                         <div className='mt-8'>
                        {otSkillsArray.map((item, i) => {
                          return (
                            <div key={i.toString()} className="mb-6">
                              <div className=''>
                                <div className="contentSkillsVacant">
                                  <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                         </div>

                        {sSkillsArray.length > 0 ? (
                        <div className="eventRightTimeLineMobile mt-8">
                          <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.sSkills")}</p> 
                        </div>
                        ) : null}
                        
                        <div className='mt-8'>
                        {sSkillsArray.map((item, i) => {
                          return (
                            <div className="mb-6" key={i.toString()}>
                              <div className=''>
                                <div className="contentSkillsVacant">
                                  <p className="pr-2 pl-2 textDivSkill desktop:text-20">{item}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        </div>
                        
                            
                        </div> 
                        <div className="  mt-10 divInfoExsisAcademyBootcampsMobile">

                        <div className="eventRightTimeLineMobile mt-8">
                        <p className='titleInfoExsisAcademy'>{i18n.t("pagejoinourteam.jobDetails.specs")}</p> 
                        </div>

                        <div className=''>
                        { (
                          specsArray.map((item, i) => {
                              return (
                              <div key={i.toString()} className=" mb-6">
                                 <div className='contentSpecsVacantMobile ml-auto mr-auto'>
                                 <div className="flex justify-center mb-2 mt-4 ">
                                        <img src={item.image} className="mr-1 mt-1"/>
                                    </div>
                                  <div className="flex flew-row text-left w-auto mt-2 "> 

                                    <div className="w-full text-left">
                                        <p className="pr-2 pl-2 titleSpec">{item.title}</p>
                                        <p className="pr-2 pl-2 textSpec">{item.text}</p>
                                    </div>
                                     
                                  </div>
                                </div>
                              </div>
                              );
                          }) 
                             )}
        

                            </div>


                        </div>

                        </div> 
                                            
                                            
                                            }                
                                
          
        </div>
    )
}



export default DescriptionVacant
