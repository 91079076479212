import React, { Fragment, useState, useContext  } from "react";

import { LoadingContext } from "../context/LoadingContext";
import i18n from "i18next";

const BannerOpenPosition = ({bannerImageContent, arrayTitle}) => {

    const [dinamicHeight, setDinamicHeight] = useState(0);
    const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0);
    const { windowWidth } = useContext(LoadingContext);


      
    return (
        <Fragment>
            <div className="container-banner-careers">

                <div className="shared-banner-container">
                {windowWidth >= 1025 ?  
                            <div className={windowWidth >= 1700 ? "flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalVacantResponsive":"flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalVacant"}>
                       
                            <div ref={el => {
                                if (el) {
                                    const resizeObserver = new ResizeObserver(entries => {
                                        setDinamicHieghtColbanner(entries[0].contentRect.height);
                                    });
                                    resizeObserver.observe(el);
                                }
                            }}
                                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                                 <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                                    <h3 className="inline-block desktop:w-10/12 desktop:mt-20 desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
                                        <span className="textTitleVacant desktop:flex">
                                        <p className="subtitleVacant">{arrayTitle.detail}</p> 
                                        </span>
                                        <span className="titleVacant">
                                        <p className="colorTextExperience2">{arrayTitle.title}</p>
                                        </span>
                                        <a href="https://www.candidatesexstaff.com.co" target="_blank" class="button">
                                        <button className='buttonAppyWork ' style={{alignSelf:'center'}}>
                                                    <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.targetVacancies.apply")}</p>
                                        </button>
                                        </a>
                                        </h3>
                                    </div>
                            </div>
                           
                        </div>:
                        <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile">
                       
                        <div ref={el => {
                            if (el) {
                                const resizeObserver = new ResizeObserver(entries => {
                                    setDinamicHieghtColbanner(entries[0].contentRect.height);
                                });
                                resizeObserver.observe(el);
                            }
                        }}
                            className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16">
                             <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                                    <h3 className="inline-block mt-24  desktop:text-left mobile:text-center mb-4 mobile:mx-2 desktop:mx-24">
                                        <span className="textTitleVacant desktop:flex">
                                        <p className="subtitleVacant">{arrayTitle.detail}</p> 
                                        </span>
                                        <span className="titleVacant">
                                        <p className="colorTextExperience2">{arrayTitle.title}</p>
                                        </span>
                                        <a href="https://www.candidatesexstaff.com.co" target="_blank" class="button">
                                        <button className='buttonAppyWork ' style={{alignSelf:'center'}}> 
                                                    <p className='textButtomSearchWork'>{i18n.t("pagejoinourteam.targetVacancies.apply")}</p>
                                        </button>
                                        </a>
                                        </h3>
                                    </div>
                        </div>
                      
                    </div>
                  }

                  
                </div>
            </div>
        </Fragment>

    )
}

BannerOpenPosition.defaultProps = {
    flag: true,
    clase: 'shared-banner-footer banner-text-white mt-8',
    style: {}
}

export default BannerOpenPosition;
